<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Nuevo Profesor</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="newProfessor.name"
                  label="Nombre"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="newProfessor.phone"
                  label="Teléfono"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="newProfessor.email"
                  label="Correo electrónico"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ManageImages :loadedURLS="newProfessor.professorImages" @updateImages="setImages" />
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="newProfessor.description"
                  label="Descripción / Biografía"
                  hint="Escriba una descripción o biografía del profesor"
                  outlined dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveProfessor()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
import ManageImages from "@/components/admin/ManageImages"
import { convertURLtoFile } from "@/libs/fileHelpers"

export default {
  name: "CreateProfessorDialog",
  components: {
    ManageImages
  },
  props:{
    value: Boolean
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //form
      newProfessor: {
        name: '',
        email: '',
        phone: '',
        professorImages: [],
        description: '',
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  methods: {
    async saveProfessor() {
      try {

        if(!this.newProfessor.name || !this.newProfessor.email || !this.newProfessor.phone || this.newProfessor.professorImages.length < 1 || !this.newProfessor.description ) return this.invalidFormNotification()

        this.isSaving = true

        let fd = new FormData()
        fd.append('name', this.newProfessor.name)
        fd.append('email', this.newProfessor.email)
        fd.append('phone', this.newProfessor.phone)
        fd.append('description', this.newProfessor.description)

        for(let i = 0; i < this.newProfessor.professorImages.length; i++) {
          const file = await convertURLtoFile(this.newProfessor.professorImages[i])
          fd.append('professorImages', file)
        }

        await this.axios.post(`${process.env.VUE_APP_APIURL}/professor`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Profesor guardado'
        this.isSnackbar = true

        this.show = false

        this.newProfessor = {
          name: '',
          email: '',
          phone: '',
          professorImages: [],
          description: '',
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al guardar el profesor'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    setImages(images) {
      this.newProfessor.professorImages = images
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>