<template>
  <div>
    <v-dialog transition="dialog-top-transition" v-model="show" max-width="800px" >

      <v-card>
        <v-card-title class="blue darken-2 white--text mb-4">
          <span>Editar Profesor</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editProfessor.name"
                  label="Nombre"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editProfessor.phone"
                  label="Teléfono"
                  outlined dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editProfessor.email"
                  label="Correo electrónico"
                  outlined dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <ManageImages :loadedURLS="editProfessor.professorImages" @updateImages="setImages" />
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="editProfessor.description"
                  label="Descripción / Biografía"
                  hint="Escriba una descripción o biografía del profesor"
                  outlined dense
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false" >
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="saveEditProfessor()" :disabled="isSaving" :loading="isSaving">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <!-- snack notification -->
    <v-snackbar
      v-model="isSnackbar"
      :color="snackcolor"
      :timeout="2000"
      top
    >
      {{ snacktext }}
    </v-snackbar>

  </div>
</template>

<script>
import ManageImages from "@/components/admin/ManageImages"
import { convertURLtoFile } from "@/libs/fileHelpers"

export default {
  name: "EditProfessorDialog",
  components: { ManageImages, },
  props:{
    value: Boolean,
    fetchId: String,
  },
  data() {
    return {
      //state
      isSaving: false,
      isSnackbar: false,
      snackcolor: 'success',
      snacktext: '',
      //form
      editProfessor: {
        name: '',
        email: '',
        phone: '',
        professorImages: [],
        description: '',
      }
    }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    },
  },
  watch: {
    show() {
      if(this.show) this.loadProfessor()
    }
  },
  methods: {
    async loadProfessor() {
      try {

        if(!this.fetchId) throw 'Missing id, cannot load info'

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/professor/id/${this.fetchId}`)

        if(response.data.data) {

          let professorImages = {}

          if(Array.isArray(response.data.data.imgURL)) {
            const imagesURLS = response.data.data.imgURL.map(img => `${process.env.VUE_APP_APIURL}/uploads/${img}`)
            professorImages = { professorImages: imagesURLS }
          }

          this.editProfessor = {...this.editProfessor, ...response.data.data, ...professorImages }
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al cargar el profesor'
        this.isSnackbar = true
        console.error(e)
      }
    },
    async saveEditProfessor() {
      try {

        if(!this.fetchId) return console.error('Missing id, cannot edit')

        if(!this.editProfessor.name || !this.editProfessor.email || !this.editProfessor.phone || this.editProfessor.professorImages.length < 1 || !this.editProfessor.description ) return this.invalidFormNotification()

        this.isSaving = true

        let fd = new FormData()
        fd.append('name', this.editProfessor.name)
        fd.append('email', this.editProfessor.email)
        fd.append('phone', this.editProfessor.phone)
        fd.append('description', this.editProfessor.description)


        for(let i = 0; i < this.editProfessor.professorImages.length; i++) {
          const file = await convertURLtoFile(this.editProfessor.professorImages[i])
          fd.append('professorImages', file)
        }

        await this.axios.put(`${process.env.VUE_APP_APIURL}/professor/id/${this.fetchId}`, fd, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })

        this.$emit('saved')

        this.snackcolor = 'success'
        this.snacktext = 'Profesor modificado'
        this.isSnackbar = true

        this.show = false

        this.editProfessor = {
          name: '',
          email: '',
          phone: '',
          professorImages: [],
          description: '',
        }

      } catch (e) {
        this.snackcolor = 'error'
        this.snacktext = 'Hubo un problema al modificar el profesor'
        this.isSnackbar = true
        console.error(e)
      } finally {
        this.isSaving = false
      }
    },
    setImages(images) {
      this.editProfessor.professorImages = images
    },
    invalidFormNotification() {
      this.snackcolor = 'error'
      this.snacktext = 'Por favor complete todos los campos'
      this.isSnackbar = true
    },
    closeDialog() { this.show = false }
  }
}
</script>