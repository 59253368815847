<template>
  <v-container>

    <v-row>
      <v-col cols="12">

        <v-row class="mb-3">
          <v-col>
            <v-btn to="/admin" color="secondary" small>
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>

        <v-card>
          <v-card-title class="pb-0">

            <v-container>

              <v-row>
                <v-col class="mb-2 py-0">
                  <p class="text-h5 mb-0">
                    <v-avatar color="orange" size="38"><v-icon dark>mdi-account</v-icon></v-avatar>
                    Gestión de Profesores
                  </p>
                </v-col>
              </v-row>

              <v-row class="mt-2 py-0">
                <v-col cols="8">
                  <v-text-field v-model="search" label="Buscar" prepend-inner-icon="mdi-magnify" single-line hide-details clearable filled rounded dense class="searchinput" />                </v-col>
                <v-col cols="4">
                  <v-btn block small color="success" class="mt-1" @click="addDialog = true" >
                    Nuevo Profesor
                  </v-btn>
                </v-col>

              </v-row>

            </v-container>
            
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="professors"
            :search="search"
            :loading="isLoading"
          >

            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="openEditDialog(item._id)">mdi-pencil</v-icon>
              <v-icon small class="mr-2" @click="openDeleteDialog(item._id)">mdi-close</v-icon>
            </template>

          </v-data-table>

        </v-card>

      </v-col>
    </v-row>

    <!-- CRUD Dialogs -->
    <CreateProfessorDialog v-model="addDialog" @saved="loadProfessors()" />
    <EditProfessorDialog v-model="editDialog" :fetchId="editFetchId" @saved="loadProfessors()" />
    <DeleteItemDialog v-model="deleteDialog" :deleteURL="deleteURL" @deleted="loadProfessors()" />

  </v-container>
</template>

<script>
import CreateProfessorDialog from "@/components/admin/CreateProfessorDialog"
import EditProfessorDialog from "@/components/admin/EditProfessorDialog"
import DeleteItemDialog from "@/components/admin/DeleteItemDialog"

export default {
  name: "AdminProfessors",
  components: {
    CreateProfessorDialog,
    EditProfessorDialog,
    DeleteItemDialog,
  },
  data() {
    return {
      //state
      search: '',
      isLoading: true,
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      editFetchId: null,
      deleteURL: '',
      //definitions
      headers: [
        { text: 'Nombre', value: 'name' },
        { text: 'Correo', value: 'email' },
        { text: 'Acciones', value: 'actions' },
      ],
      professors: []
    }
  },
  methods: {
    async loadProfessors() {
      try {

        this.isLoading = true

        const response = await this.axios.get(`${process.env.VUE_APP_APIURL}/professor`)
        this.professors = response.data.data

      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    openEditDialog(id) {
      this.editFetchId = id
      this.editDialog = true
    },
    openDeleteDialog(id) {
      this.deleteURL = `${process.env.VUE_APP_APIURL}/professor/id/${id}`
      this.deleteDialog = true
    },
  },
  mounted() {
    this.loadProfessors()
  }
}
</script>
